import * as React from "react"
import { StaticImage } from "gatsby-plugin-image";
import { Container, Typography, Box, Link, useTheme } from '@mui/material'

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => {
  const theme = useTheme();

  return (
  <Layout>
    <Seo title="About" />
    <Container sx={{ my: 4 }}>
      <Typography component="h1" variant="h2" gutterBottom>Welcome to Radiant Family Home</Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <Box sx={{ width: { xs: '100%', md: '60%' } }}>
          <Typography variant="body1" sx={{my: 2}}>
            Radiant Family Home is a website dedicated to bringing you articles, tips, and guides about how to live a more eco-friendly and sustainable life.
          </Typography>
          <Typography variant="body1" sx={{my: 2}}>
            We believe in <Typography variant="body1" sx={{fontWeight:'900', display:'inline-block', color: theme.palette.primary.main}}>lowering consumption</Typography> without sacrificing happiness.
          </Typography>
          <Typography variant="body1" sx={{my: 2}}>
            We believe in using <Typography variant="body1" sx={{fontWeight:'900', display:'inline-block', color: theme.palette.primary.main}}>renewable energy</Typography> when possible.
          </Typography>
          <Typography variant="body1" sx={{my: 2}}>
            We believe in supporting <Typography variant="body1" sx={{fontWeight:'900', display:'inline-block', color: theme.palette.primary.main}}>local and green businesses</Typography> at every opportunity.
          </Typography>
          <Typography variant="body1" sx={{my: 2}}>
            We believe in choosing <Typography variant="body1" sx={{fontWeight:'900', display:'inline-block', color: theme.palette.primary.main}}>organic</Typography> ingredients.
          </Typography>
          <Typography variant="body1" sx={{my: 2}}>
            We believe <Typography variant="body1" sx={{fontWeight:'900', display:'inline-block', color: theme.palette.primary.main}}>less waste</Typography> is better.
          </Typography>
          <Typography variant="body1" sx={{my: 2}}>
            We also believe that we as consumers can only do so much in this battle against climate change. According to the <Link href="https://b8f65cb373b1b7b15feb-c70d8ead6ced550b4d987d7c03fcdd1d.ssl.cf3.rackcdn.com/cms/reports/documents/000/002/327/original/Carbon-Majors-Report-2017.pdf?1499691240" target="_blank">Carbon Majors Report</Link>, just 100 companies have been the source of more than 70% of the world’s greenhouse gas emissions since 1988. In addition to making changes to our lifestyles, we also have to support companies that make environmentally-friendly decisions and penalize those that don't.
          </Typography>
          <Typography variant="body1" sx={{my: 2}}>
            At Radiant Family Home, we are committed to bringing you the information you need to make better decisions.
          </Typography>
          <Typography variant="h4" sx={{my: 2, color: theme.palette.primary.main}}>
            Helping you help the world.
          </Typography>
        </Box>
        <Box>
          <StaticImage
            src="../images/eco-friendly.jpg"
            placeholder="blurred"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Eco-friendly seal of approval"
            style={{ opacity: .6 }}
          />
        </Box>
      </Box>
    </Container>
  </Layout>
)}

export default AboutPage